import React from 'react';
import Autoplay from 'embla-carousel-autoplay';
import useEmblaCarousel from 'embla-carousel-react'
import { BackgroundImage } from '@mantine/core';
import { homepageStyles as useStyles } from './Styles/HomeStyles';

const Home = () => {
    const { classes } = useStyles();

    const autoplayOptions = {  delay: 2500, stopOnInteraction: false }
    const [emblaRef] = useEmblaCarousel({ loop: true }, [Autoplay(autoplayOptions)])


    return (
        <div className="overflow-hidden w-full h-full" ref={emblaRef}>
            <div className="flex w-full h-full">
                <div className={classes.slides}>
                    <BackgroundImage className={classes.images} src={"/Images/Homepage1.jpg"} />
                </div>
                {/* <div className={[classes.slides, classes.hiddenDesktop]}>
                    <BackgroundImage className={classes.images} src={'https://res.cloudinary.com/dp22pyubg/image/upload/v1700898651/Featured/Emails/7bca4085-28b5-443f-a973-df26b25cc157_shxidh.jpg'} />
                </div> */}
                <div className={classes.slides}>
                    <BackgroundImage className={classes.images} src={'https://res.cloudinary.com/dp22pyubg/image/upload/v1700898652/Featured/Emails/avanti-featured_impbwa.jpg'} />
                </div>
                <div className={classes.slides}>
                    <BackgroundImage className={classes.images} src={'https://res.cloudinary.com/dp22pyubg/image/upload/v1700898650/Featured/Emails/professional_ywiucz.jpg'} />
                </div>
                <div className={classes.slides}>
                    <BackgroundImage className={classes.images} src={'https://res.cloudinary.com/dp22pyubg/image/upload/v1700898525/At_Work/BNG_Juli_Demo015_e2nrqj.jpg'} />
                </div>
                {/* <div className={[classes.slides, classes.hiddenDesktop]}>
                    <BackgroundImage className={classes.images} src={'https://res.cloudinary.com/dp22pyubg/image/upload/v1700898653/Featured/Emails/cutom-blown_pecolt.jpg'} />
                </div>
                <div className={[classes.slides, classes.hiddenDesktop]}>
                    <BackgroundImage className={classes.images} src={'https://res.cloudinary.com/dp22pyubg/image/upload/v1689924689/Featured/display_ljcu9h.png'} />
                </div> */}
            </div>
        </div>
    )
}

export default Home