import React from 'react';
import { Blockquote } from '@mantine/core';
import { artistBio } from '../../../Data/data';

const Bio = () => {
    return (
        <div className='flex w-full pb-8 justify-center'>
            <div className='w-full flex flex-col 520:max-w-[700px] max-w-[400px]'>
                <div className='flex text-white py-8 520:px-4 text-2xl font-light uppercase'>
                    Artist Biography
                </div>

                <div className='flex flex-col space-y-4'>
                    <img
                        src='https://res.cloudinary.com/dp22pyubg/image/upload/v1700557317/juli-bio.png'
                        className='520:max-w-[700px] max-w-[400px] w-auto h-auto 520:px-4'
                        alt='juli'
                    />
                </div>

                <div>
                    {artistBio.map((text, index) => (
                        <Blockquote icon={null}>
                            {text}
                        </Blockquote>

                    ))}

                </div>
            </div>
        </div>
    )
}

export default Bio

//flex flex-row justify-center space-x-24 //Conatiner

// flex w-[75vh] justify-center object-cover overflow-hidden //Image