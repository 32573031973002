import React from 'react';
import { pressImages } from '../../../Data/data';
import { SimpleGrid } from '@mantine/core';
import { VideoPlayer } from '../../../Components/Globals/VideoPlayer';

const Press = () => {

    return (
        <div className='flex w-full pb-8 justify-center'>
            <div className='w-full flex flex-col 820:max-w-[820px] max-w-[400px]'>
                <div className='flex text-white py-8 text-2xl font-light uppercase'>
                    Press
                </div>

                <div className='w-full flex justify-center'>
                    <SimpleGrid cols={2} spacing="sm" breakpoints={[
                        { maxWidth: 1400, cols: 2, spacing: 'md' },
                        { maxWidth: 820, cols: 1, spacing: 'sm' },
                    ]}>
                        {pressImages.map((item) => (
                            <a
                                href={item.href}
                                target="_blank"
                                rel='noreferrer'
                                className={!item.href ? null : 'hover:opacity-50'}
                            >
                                {item.image_url.split('/')[4] === 'image' ?
                                    <img
                                        src={item.image_url}
                                        className={'520:w-[500px] max-w-[400px] max-h-[900px]'}
                                        height={item.height}
                                        alt={''}
                                    />
                                    : <VideoPlayer
                                        image_url={item.image_url}
                                    />}
                            </a>
                        ))}
                    </SimpleGrid>
                </div>
            </div>
        </div>
    )
}

export default Press