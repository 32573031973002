import React from 'react';
import { Outlet } from 'react-router-dom';
import Navbar from '../../Components/Globals/Navbar';

const MainLayout = () => {
    return (
        <div className='flex flex-col flex-container min-h-screen bg-black'>
            <Navbar />

            <div className='flex min-h-[calc(100vh-40px)] max-w-screen'>
                <Outlet />
            </div>
        </div>
    )
}

export default MainLayout