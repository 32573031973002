import { createStyles, rem } from "@mantine/core";


export const navbarStyles = createStyles((theme) => ({
    header: {
        backgroundColor: 'black',
        alignContent: 'center',
        borderBottom: 'gray 1px solid'
    },

    inner: {
        display: 'flex',
        width: '100%',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: 'none',
        // backgroundColor: 'black'
      },

    mobileHeader: {
        display: 'flex',
        flexDirection: 'col',
        backgroundColor: 'black',
        // alignContent: 'center',
        borderBottom: 'gray 1px solid',
        paddingLeft: '0',
        // justifyContent: 'between',
    },

    link: {
        display: 'flex',
        alignItems: 'center',
        height: '100%',
        paddingLeft: theme.spacing.md,
        paddingRight: theme.spacing.md,
        textDecoration: 'none',
        color: 'gray',
        fontWeight: 500,
        fontSize: theme.fontSizes.sm,

        [theme.fn.smallerThan('lg')]: {
            paddingLeft: theme.spacing.sm,
            paddingRight: theme.spacing.sm,
        },

        [theme.fn.smallerThan('sm')]: {
            height: rem(42),
            display: 'flex',
            alignItems: 'center',
            width: '100%',
        },

        ...theme.fn.hover({
            backgroundColor: theme.colors.dark[6],
            color: 'white',
            // textDecoration: 'underline'
        }),
    },

    socialLinks: {
        color: 'gray',
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer',


        ...theme.fn.hover({
            color: 'white',
        }),

    },

    subLink: {
        width: '100%',
        padding: `${theme.spacing.xs} ${theme.spacing.md}`,
        borderRadius: theme.radius.md,

        ...theme.fn.hover({
            backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.colors.gray[0],
        }),

        '&:active': theme.activeStyles,
    },

    drawer: {
        backgroundColor: theme.colors.dark[6],
        color: theme.colors.dark[6]
    },

    dropdownFooter: {
        backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.colors.gray[0],
        margin: `calc(${theme.spacing.md} * -1)`,
        marginTop: theme.spacing.sm,
        padding: `${theme.spacing.md} calc(${theme.spacing.md} * 2)`,
        paddingBottom: theme.spacing.xl,
        borderTop: `${rem(1)} solid ${theme.colorScheme === 'dark' ? theme.colors.dark[5] : theme.colors.gray[1]
            }`,
    },

    collectionLink: {
        textDecoration: 'none',
        fontSize: theme.fontSizes.xs,
        color: theme.colors.dark[0],
        lineHeight: 1,
        fontWeight: 500,

        // '&:hover': {
        //     backgroundColor: theme.colors.dark[6],
        //     color: theme.white,
        // },
    },

    hiddenMobile: {
        [theme.fn.smallerThan('sm')]: {
            display: 'none',
        },
    },

    hiddenDesktop: {
        [theme.fn.largerThan('sm')]: {
            display: 'none',
        },
    },
}));

export const useUserButtonStyles = createStyles((theme) => ({
    user: {
      display: 'block',
      width: '100%',
      padding: theme.spacing.md,
      color: theme.colors.dark[0],
  
      '&:hover': {
        backgroundColor: theme.colors.dark[8],
      },
    },
  }));