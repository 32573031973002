import React, { useState, useEffect } from 'react';
import { Accordion, Button } from '@mantine/core';
import { useNavigate, generatePath } from 'react-router-dom';
import api from '../../../api/axios';

const ShopEdit = () => {

    const navigate = useNavigate();

    const [items, setItems] = useState();
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchItems = async () => {
            try {
                const response = await api.get('/fetch-shop-items')
                setLoading(false);
                setItems(response.data);

            } catch (err) {
                console.log(err.message);
            }
        }

        fetchItems();
    }, []);

    const handleClick = (id) => {
        const path = generatePath('/juli+dashboard/edit/item/:id', {
            id: id
        });
        navigate(path);
    }

    return (
        <div className='px-8'>
            <div className='flex justify-center text-lg uppercase text-white pb-4 '>
                Edit an Item
            </div>
            <Accordion variant="contained" radius="lg">
                {!loading && items.map((item) => (
                    <Accordion.Item value={item.title}>
                        <Accordion.Control>
                            {item.title} : ${item.price}
                        </Accordion.Control>
                        <Accordion.Panel>
                            <Button onClick={() => handleClick(item.id)}>
                                Edit this Item    
                            </Button>    
                        </Accordion.Panel>
                    </Accordion.Item>
                ))}
            </Accordion>
        </div>
    )
}

export default ShopEdit