import axios from './../api/axios';
import useAuth from './useAuth';

const useRefreshToken = () => {
    const { setAuth } = useAuth();

    const refresh = async () => {
        const response = await axios.get('/refresh', {
            withCredentials: true
        });
        setAuth(prev => {
            // console.log(response.data.user);
            return { 
                ...prev, 
                firstName: response.data.firstName,
                lastName: response.data.lastName,
                accessToken: response.data.accessToken,
                email: response.data.userEmail,
                credentials: response.data.credentials
            }
        });
        
        return response.data.accessToken;
    };

    return refresh
}

export default useRefreshToken