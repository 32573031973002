import { createStyles } from "@mantine/core";


export const homepageStyles = createStyles((theme) => ({
    slides: {
        flex: '0 0 100%',
        minWidth: '0',
    },

    images: {
        height: 'calc(100vh - 40px)',
        backgroundSize: 'cover',
        backgroundPosition: 'center'
    },

    hiddenDesktop: {
        [theme.fn.largerThan('md')]: {
            display: 'none',
        },
    },
}))