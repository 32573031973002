import React from 'react';
import { VideoPlayer } from '../../Components/Globals/VideoPlayer';
import { memorialImages } from '../../Data/data';


const Memorials = () => {
    return (
        <div className='flex w-full pb-8 justify-center'>
            <div className='w-full flex flex-col 520:max-w-[500px] max-w-[400px]'>
                <div className='flex text-white py-8 text-2xl font-light uppercase'>
                    Memorials Pieces
                </div>

                <div className='text-white'>
                    Allow me to create a special memorial piece for you encasing the ashes of your loved ones or pets.
                    You choose the colors for your hand sculpted glass memorial piece.
                    I will encase the ashes with clear glass surrounding and protecting them.
                </div>

                <div className='text-white pt-4'>
                    Sizes from 2 1/2”- 6“
                </div>

                <div className='text-white'>
                    Price: $100
                </div>

                <div className='text-white pb-4'>
                    Contact me for more details
                </div>

                <div className='flex flex-col space-y-4'>

                    {memorialImages.map((item) => (
                        <div className='flex justify-center' key={item.image_url}>
                            {item.image_url.split('/')[4] === 'image' ?
                                <img
                                    src={item.image_url}
                                    className='520:max-w-[500px] max-w-[400px] w-auto h-auto'
                                    alt={item.alt}
                                />
                                : <VideoPlayer
                                    image_url={item.image_url}
                                />
                            }
                        </div>
                    ))}
                </div>
                {/* </InfiniteScroll> */}
            </div>
        </div>
    )
}

export default Memorials