import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from '@mantine/core';
import { dashMenu } from '../../../Data/data';

const DashHome = () => {

    const navigate = useNavigate();

    const handleClick = (link) => {
        navigate(link)
    }

    return (
        <div className='flex flex-col justify-center'>
            <div className='p-12'>

                {dashMenu.map((item) => (
                    <div className='flex flex-col w-full justify-center pt-8'>
                        <Button 
                            onClick={() => handleClick(item.link)}
                            className='flex text-slate-400 no-underline justify-center h-16 text-xl hover:text-white'
                        >
                            {item.label}
                        </Button>
                    </div>
                ))}
            </div>
        </div>
    )
}

export default DashHome