const items = [
    { image_url: 'https://res.cloudinary.com/dp22pyubg/image/upload/v1700898732/Happy_Clients/shreveport_zrq4jk.jpg', text: '', alt: '' },
    { image_url: 'https://res.cloudinary.com/dp22pyubg/image/upload/v1700898734/Happy_Clients/happy-cat_nimzyo.jpg', text: '', alt: '' },
    { image_url: 'https://res.cloudinary.com/dp22pyubg/image/upload/v1715807589/Happy_Clients/FullSizeRender_x2c0e7.jpg', text: '', alt: '' },
    { image_url: 'https://res.cloudinary.com/dp22pyubg/image/upload/v1715807589/Happy_Clients/image000000_vjbrkj.jpg', text: '', alt: '' },
    { image_url: 'https://res.cloudinary.com/dp22pyubg/image/upload/v1715807590/Happy_Clients/image000001_m3te6i.jpg', text: '', alt: '' },
    { image_url: 'https://res.cloudinary.com/dp22pyubg/image/upload/v1715807590/Happy_Clients/IMG_0251_jdkx3j.jpg', text: '', alt: '' },
    { image_url: 'https://res.cloudinary.com/dp22pyubg/image/upload/v1715807591/Happy_Clients/IMG_0326_ikv5qh.jpg', text: '', alt: '' },
    { image_url: 'https://res.cloudinary.com/dp22pyubg/image/upload/v1715807592/Happy_Clients/IMG_0973_mz1ktw.jpg', text: '', alt: '' },
    { image_url: 'https://res.cloudinary.com/dp22pyubg/image/upload/v1715807592/Happy_Clients/IMG_0974_jpmqm5.jpg', text: '', alt: '' },
    { image_url: 'https://res.cloudinary.com/dp22pyubg/image/upload/v1715807593/Happy_Clients/IMG_1823_aylpib.jpg', text: '', alt: '' },
    { image_url: 'https://res.cloudinary.com/dp22pyubg/image/upload/v1715807594/Happy_Clients/IMG_2060_rvwsh8.jpg', text: '', alt: '' },
    { image_url: 'https://res.cloudinary.com/dp22pyubg/image/upload/v1715807594/Happy_Clients/IMG_2670_ftpjjw.jpg', text: '', alt: '' },
    { image_url: 'https://res.cloudinary.com/dp22pyubg/image/upload/v1715807595/Happy_Clients/IMG_2676_wat9th.jpg', text: '', alt: '' },
    { image_url: 'https://res.cloudinary.com/dp22pyubg/image/upload/v1715807596/Happy_Clients/IMG_2723_ysnp9m.jpg', text: '', alt: '' },
    { image_url: 'https://res.cloudinary.com/dp22pyubg/image/upload/v1715807597/Happy_Clients/IMG_3882_arwu3h.jpg', text: '', alt: '' },
    { image_url: 'https://res.cloudinary.com/dp22pyubg/image/upload/v1715807597/Happy_Clients/IMG_3969_fkyooy.jpg', text: '', alt: '' },
    { image_url: 'https://res.cloudinary.com/dp22pyubg/image/upload/v1715807598/Happy_Clients/IMG_3970_isber6.jpg', text: '', alt: '' },
    { image_url: 'https://res.cloudinary.com/dp22pyubg/image/upload/v1715807599/Happy_Clients/IMG_7313_fvwz3c.jpg', text: '', alt: '' },
];


export const calculateSlice = (items, setItems, fetchCount) => {
    const itemsLength = items.length;

    const pagesTotal = Math.ceil(itemsLength / 5);

    if (fetchCount === pagesTotal - 1) {
        setItems(items.slice(0, itemsLength))
    } else {
        setItems(items.slice(0, ((fetchCount + 1) * 5)))
    }
}

// export const calculatedInstallSlice = (items, fetchCount) => {
//     const itemsLength = items.length;

//     const pagesTotal = Math.ceil(itemsLength / 5);

//     if (fetchCount === pagesTotal - 1) {
//         setItems(items.slice(0, itemsLength))
//     } else {
//         setItems(items.slice(0, ((fetchCount + 1) * 5)))
//     }
// }

// calculateSlice(items)