import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import api from '../../../api/axios';
import { Button, Accordion, Modal } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';

const ShopDelete = () => {

    const [opened, { open, close }] = useDisclosure(false);
    const [items, setItems] = useState();
    const [itemToDelete, setItemToDelete] = useState(null);
    const [loading, setLoading] = useState(true);

    const navigate = useNavigate();

    useEffect(() => {
        const fetchItems = async () => {
            try {
                const response = await api.get('/fetch-shop-items')
                setItems(response.data);
                setLoading(false);

            } catch (err) {
                console.log(err.message);
            }
        }

        fetchItems();
    }, []);

    const handleDelete = async (id) => {
        try {
            const response = await api.post('/delete/item', {
                id: id
            })

            if (response.data === 'fail') {
                navigate('/juli+dashboard/upload/failure')
            } else {
                navigate('/juli+dashboard/upload/success')
            }


        } catch (err) {
            console.log(err.message);
        }
    }

    return (
        <div className='px-8'>
            <div className='flex justify-center text-lg uppercase text-white pb-4 '>
                Delete an Item
            </div>

            <Accordion variant="contained" radius="lg">
                {!loading && items.map((item) => (
                    <Accordion.Item value={item.title}>
                        <Accordion.Control>
                            {item.title} : ${item.price}
                        </Accordion.Control>
                        <Accordion.Panel>
                            <Button onClick={() => {open(); setItemToDelete(item.id) }}>
                                Delete this Item
                            </Button>
                        </Accordion.Panel>
                    </Accordion.Item>
                ))}
            </Accordion>

            <Modal
                styles={(theme) => ({
                    inner: {
                        // backgroundColor: '#00acee',
                        border: 0,
                        paddingLeft: '0 !important'
                    },
                })}
                opened={opened} 
                onClose={() => {close(); setItemToDelete()}} 
                title="Confirmation" 
                centered
            >
                <div className='flex flex-col'>
                    <div>
                        Are you sure you want to delete this item?
                    </div>

                    <div className='flex flex-row gap-4 pt-4'>
                        <Button onClick={() => handleDelete(itemToDelete)}>
                            Delete
                        </Button>
                        <Button onClick={() => {close(); setItemToDelete()}}>
                            Cancel
                        </Button>
                    </div>
                </div>
            </Modal>
        </div>
    )
}

export default ShopDelete