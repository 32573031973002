import React from 'react';
import { useEffect, useState, useCallback } from 'react';
import { Button, Select, TextInput, Title, Modal, Textarea, Paper } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { useItemUploadStyles as useStyles } from '../DashStyles/DashStyles';
import { useForm } from '@mantine/form';
import api from '../../../api/axios';
import { uploadProductTypes as data } from '../../../Data/data';
import { useDropzone } from 'react-dropzone';

const UploadInputs = () => {

    const { classes } = useStyles();

    const [image, setImage] = useState([]);
    const [success, setSuccess] = useState();
    const [imgInputError, setImgInputError] = useState(false);
    const [path, setPath] = useState([]);

    const [openedSuccess, { openSuccess, closeSuccess }] = useDisclosure(false);
    const [openedFailure, { openFailure, closeFailure }] = useDisclosure(false);


    const form = useForm({
        initialValues: { title: '', type: '', price: '', size: '' },
        validate: (values) => ({
            title:
                values.title.length === 0
                    ? 'Product title is required'
                    : null,
            type:
                values.type.length === 0
                    ? 'Product type is required'
                    : null,
            price:
                values.price.length === 0
                    ? 'Price is required'
                    : /^[+-]?[0-9]{1,3}(?:,?[0-9]{3})*(?:\.[0-9]{2})?$/.test(values.price)
                        ? null
                        : 'Price should only contain numbers ',
            size:
                values.size.length === 0
                    ? 'Size is required'
                    : null,
        }),
    });

    const handleSubmit = async (values) => {
        try {
            if (image.length === 0) {
                setImgInputError(true);
                return
            };

            // toggle();

            const input = data.find(o => o.selector === values.type);

            const response = await api.post('/itemupload', {
                title: values.title,
                type: input.input,
                description: values.description,
                price: parseFloat(values.price),
                size: values.size,
                image: image
            });

            if (response.data[0].id) {
                setSuccess(true);
            } else {
                setSuccess(false);
            }

        } catch (err) {
            console.log(err.message);
        } finally {
            setImage();
            setPath();
        }
    };

    const onDrop = useCallback((acceptedFiles) => {
        acceptedFiles.forEach((file) => {
            const reader = new FileReader();

            reader.onabort = () => console.log('file reading was aborted');
            reader.onerror = () => console.log('file reading has failed');
            reader.onload = (event) => {
                // Do whatever you want with the file contents
                setImage(oldArray => [...oldArray, event.target.result]);
            }
            reader.readAsDataURL(file);
            setPath(oldArray => [...oldArray, acceptedFiles.map(file => URL.createObjectURL(file))]);
        });
    }, []);

    // console.log(path);

    const { getRootProps, getInputProps } = useDropzone({ onDrop });

    const handleImageClick = () => {
        setImage();
        setPath();
    };

    useEffect(() => {
        const navigateAfterUpload = () => {
            if (success === true) {
                openSuccess();
            }
            if (success === false) {
                openFailure();
            }
        }
        navigateAfterUpload();
    }, [success]);

    return (
        <div className='flex flex-col p-8 '>

            <Title className='mb-8 text-zinc-400 font-light'>
                Upload a New Item to the Shop
            </Title>

            <Modal opened={openedSuccess} onClose={closeSuccess} title="Success">
                Edit Successful
            </Modal>

            <Modal opened={openedFailure} onClose={closeFailure} title="Failure">
                Edit Failure Please Try Again
            </Modal>

            <form onSubmit={form.onSubmit((values) => handleSubmit(values))}>
                <div className='flex flex-row items-center justify-center space-x-4'>
                    <div className='flex flex-col items-center space-y-4 w-full '>
                        <TextInput
                            withAsterisk
                            label="Product Title"
                            width={400}
                            placeholder="Title"
                            classNames={classes}
                            {...form.getInputProps('title')}
                        />
                        <Select
                            withAsterisk
                            withinPortal
                            data={data.map((item) => item.selector)}
                            placeholder="Select"
                            label="Product Type"
                            classNames={classes}
                            {...form.getInputProps('type')}
                        />
                        <TextInput
                            withAsterisk
                            label="Price"
                            placeholder="Price"
                            classNames={classes}
                            {...form.getInputProps('price')}
                        />
                        <Textarea
                            label="Description"
                            placeholder="Description (optional)"
                            autosize
                            classNames={classes}
                            {...form.getInputProps('description')}
                        />
                        <TextInput
                            withAsterisk
                            label="Size"
                            placeholder="X' x X' x X'"
                            classNames={classes}
                            {...form.getInputProps('size')}
                        />
                    </div>
                </div>

                <div className='flex justify-center'>
                    <div
                        {...getRootProps()}
                        className={imgInputError
                            ? 'flex w-full rounded-md border-[1px] border-solid border-red-500 justify-center mt-4 bg-[#25262b] p-4 cursor-pointer hover:opacity-75'
                            : 'flex w-full rounded-md border-[1px] border-solid border-[#373a40] justify-center mt-4 bg-[#25262b] p-4 cursor-pointer hover:opacity-75'
                        }
                    >
                        <input {...getInputProps()} />
                        <p className='font-light'>Drag 'n' drop some files here, or click to select files</p>
                    </div>
                </div>

                {path
                    ? <div className='flex flex-col w-full justify-center mt-4 hover:opacity-50 cursor-pointer'>
                        {path.map((img, index) => (
                            <div onClick={handleImageClick} className='flex w-full justify-center mt-4'>
                                <img key={path[index]} src={path[index]} className='w-[200px] h-auto' />
                            </div>
                        ))}

                    </div>
                    : <></>}

                <div className='flex justify-center mt-8'>
                    <Button
                        color='yellow'
                        variant='outline'
                        type='submit'
                        className={classes.inner}
                    >
                        Add to shop
                    </Button>
                </div>
            </form>
        </div>
    );
}

export default UploadInputs