import { AdvancedVideo } from '@cloudinary/react';
import { Cloudinary } from "@cloudinary/url-gen";

export const VideoPlayer = (image_url) => {
    const cld = new Cloudinary({
        cloud: {
            cloudName: 'dp22pyubg'
        }
    });

    let url_frag = image_url.image_url.split('/');
    
    const publicId = url_frag[7] + '/' + url_frag[8];

    const myVideo = cld.video(publicId);

    return (
        <AdvancedVideo
            cldVid={myVideo}
            controls
            className='520:max-w-[500px] max-w-[400px] w-auto h-auto'
        />
    )
}