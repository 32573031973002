import { createStyles, rem } from "@mantine/core";


export const useContactStyles = createStyles((theme) => ({
    wrapper: {
        display: 'flex',
        justifyContent: 'center',
        alignContent: 'middle',
        maxWidth: 800,
        // maxHeight: 500,
        boxSizing: 'border-box',
        borderRadius: theme.radius.md,
        padding: `calc(${theme.spacing.xl} * 2.5)`,

        [theme.fn.smallerThan('sm')]: {
            padding: `calc(${theme.spacing.xl} * 1.5)`,
        },
    },

    title: {
        fontFamily: `Greycliff CF, ${theme.fontFamily}`,
        color: theme.colors.white,
        fontWeight: '500',
        lineHeight: 1,
    },

    description: {
        color: theme.colors.gray[6],
        maxWidth: rem(300),

        [theme.fn.smallerThan('sm')]: {
            maxWidth: '100%',
        },
    },

    form: {
        borderRadius: theme.radius.md,
        boxShadow: theme.shadows.lg,
    },

    social: {
        color: theme.colors.gray[6],

        '&:hover': {
            color: theme.colors[theme.primaryColor][1],
        },
    },
}));

export const useIconStyles = createStyles((theme) => ({
    wrapper: {
        display: 'flex',
        alignItems: 'center',
    },

    icon: {
        color: theme.colors.gray[6],
    },

    title: {
        color: theme.colors.white,
    },

    description: {
        color: theme.colors.white,
    },
}));