import React, { useEffect, useState } from 'react';
import { clientImages } from '../../../Data/data';
import { VideoPlayer } from '../../../Components/Globals/VideoPlayer';
import { calculateSlice } from '../../../Components/Globals/Scroller/imageSliceFunctions';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Skeleton } from '@mantine/core';

const Clients = () => {
    const [items, setItems] = useState(clientImages.slice(0, 5));
    const [fetchCount, setFetchCount] = useState(0);

    useEffect(() => {
        calculateSlice(clientImages, setItems, fetchCount);
    }, [fetchCount])

    function fetchMoreListItems() {
        setTimeout(() => {
            setFetchCount(current => current + 1);
        }, 1000);
    }

    return (
        <div className='flex w-full pb-8 justify-center'>
            <div className='w-full flex flex-col 520:max-w-[500px] max-w-[400px]'>
                <div className='flex text-white py-8 text-2xl font-light uppercase'>
                    Happy Clients
                </div>

                <InfiniteScroll
                    dataLength={items.length}
                    next={fetchMoreListItems}
                    hasMore={(fetchCount + 1 < (Math.floor(clientImages.length / 5))) ? true : false}
                    loader={
                        <Skeleton
                            height={520}
                            classNames={{ width: '520:max-w-[500px] max-w-[400px] w-auto', radius: 'rounded-none' }}
                            className='mt-4 rounded-none'
                        />}
                >

                    <div className='flex flex-col space-y-4'>
                        {items.map((item) => (
                            <div className='flex justify-center'>
                                {item.image_url.split('/')[4] === 'image' ?
                                    <img
                                        src={item.image_url}
                                        className='520:max-w-[500px] max-w-[400px] w-auto h-auto'
                                        alt={item.alt}
                                    />
                                    : <VideoPlayer
                                        image_url={item.image_url}
                                    />
                                }
                            </div>
                        ))}
                    </div>
                </InfiniteScroll>
            </div>
        </div>
    )
}

export default Clients