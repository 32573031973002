import React, { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { TextInput, PasswordInput, Checkbox, Anchor, Paper, Title, Text, Container, Group, Button } from '@mantine/core';
import { useForm } from '@mantine/form';
import axios from '../../api/axios';
import useAuth from '../../hooks/useAuth';

const Login = () => {
    const { auth, setAuth } = useAuth();
    const navigate = useNavigate();

    const submitLogin = async (data) => {
        try {
            const response = await axios.post('/signin', (data),
                {
                    headers: { 'Content-Type': 'application/json' },
                    withCredentials: true
                }
            );

            const firstName = response?.data?.firstName
            const lastName = response?.data?.lastName
            const accessToken = response?.data?.accessToken;
            const email = response?.data?.userEmail;
            const credentials = response?.data?.credentials;

            setAuth({ firstName, lastName, accessToken, email, credentials });

            navigate('/juli+dashboard');
        } catch (err) {
            if (err?.response?.status === 401) {
                form.setErrors({ email: 'Incorrect login information' })
                // setError('root.serverError', { 
                //     type: err.response.status,
                // })
            }
        }
    };

    const form = useForm({
        initialValues: {
            email: '',
            password: ''
        },
        validate: (values) => ({
            email:
                values.email.length === 0
                    ? 'Address is required'
                    : /^\S+@\S+$/.test(values.email)
                        ? null
                        : 'Invalid email format',
            password:
                values.password.length === 0
                    ? 'Address is required'
                    : null,
        })
    });

    return (
        <div className='flex w-full items-center sm:items-start -mt-24 sm:mt-0'>
            <Container size={420} my={40}>
                <Title
                    align="center"
                    sx={(theme) => ({ fontFamily: `Greycliff CF, ${theme.fontFamily}`, fontWeight: 900 })}
                    className='text-zinc-500'
                >
                    Welcome Juli!
                </Title>
                <Text color="dimmed" size="sm" align="center" mt={5}>
                    Sign in below{' '}
                </Text>

                <Paper withBorder shadow="md" p={30} mt={30} radius="md">
                    <form onSubmit={form.onSubmit((values) => submitLogin(values))}>
                        <TextInput
                            label="Email"
                            placeholder="you@dashpond.com"
                            // required
                            {...form.getInputProps('email')}
                        />
                        <PasswordInput
                            label="Password"
                            placeholder="Your password"
                            // required 
                            mt="md"
                            {...form.getInputProps('password')}
                        />
                        <Group position="apart" mt="lg">
                            <Checkbox label="Remember me" />
                            <Anchor component="button" size="sm">
                                Forgot password?
                            </Anchor>
                        </Group>
                        <Button type='submit' fullWidth mt="xl">
                            Sign in
                        </Button>
                    </form>
                </Paper>
            </Container>
        </div>
    );
}

export default Login