import React from 'react';
import { Header, HoverCard, Group, UnstyledButton, Burger, Drawer, ScrollArea, rem, Container, Menu } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { navbarStyles as useStyles } from './Styles/GlobalStyles';
import { navLinks } from '../../Data/data';
import { IconBrandInstagram, IconBrandFacebookFilled, IconBrandTiktokFilled, IconBrandPinterest, IconBrandTwitterFilled, IconChevronDown } from '@tabler/icons-react';
import Logo from './Logo'
import { dashNavCollections, BASE_URL } from '../../Data/data';

const Navbar = () => {
    const [drawerOpened, { toggle: toggleDrawer, close: closeDrawer }] = useDisclosure(false);
    const { classes } = useStyles();
    const location = useLocation();

    const pathname = window.location.pathname;

    const smallPath = pathname.split('/')[1]

    return (
        <div className='sticky top-0 z-50 '>
            <Header height={40} px="md" className={[classes.header, classes.hiddenMobile]}>
                <Group position="apart" sx={{ height: '100%' }}>
                    <Link to='/'>
                        <div className='ml-8 md:ml-0 flex align-middle'>
                            <Logo />
                        </div>
                    </Link>

                    <Group sx={{ height: '100%' }} spacing={0} className='md:gap-0'>
                        {navLinks.map((item, index) => (
                            <HoverCard closeDelay={0} key={item.itemTitle}>
                                <HoverCard.Target>
                                    <a 
                                        href={`${BASE_URL}${item.link}`} 
                                        className={[classes.link, pathname === item.link || smallPath === item.itemTitle.toLowerCase()
                                            ? 'uppercase font-semibold tracking-tight text-white' 
                                            : 'uppercase font-semibold tracking-tight']} 
                                    >
                                        {item.itemTitle}
                                    </a>
                                </HoverCard.Target>
                                {index > 1 && index < 3 ?
                                    <HoverCard.Dropdown className='-mt-2 bg-black border-l border-r border-b rounded-none border-zinc-700 p-2'>
                                        {item.dropdownItems.map((dropdownItems, index) => (
                                            <div key={dropdownItems.title}>
                                                <div 
                                                    className={' align-middle -mt-1 py-1 tracking-tight uppercase' }>
                                                    <a
                                                        href={`${BASE_URL}${dropdownItems.link}`}
                                                        className={pathname === dropdownItems.link 
                                                            ? 'text-white no-underline transition duration-300 delay-100 text-sm '
                                                            : 'text-zinc-500 hover:text-white no-underline transition duration-300 delay-100 text-sm '}
                                                    >
                                                        {dropdownItems.title}
                                                    </a>
                                                </div>
                                                {item.dropdownItems.length > 1 && index != item.dropdownItems.length - 1
                                                    ? <hr className='h-px bg-gray-200 border-0 dark:bg-gray-700' />
                                                    : <></>
                                                }
                                            </div>
                                        ))}
                                    </HoverCard.Dropdown> : <></>}
                            </HoverCard>
                        ))}
                    </Group>

                    <Group className='mr-8 md:mr-0 space-x-.5'>
                        <div className='flex gap-.5'>
                            <a
                                href='https://www.facebook.com/NomadCollection'
                                target='_blank'
                                rel='noreferrer'
                            >
                                <IconBrandFacebookFilled size={20} className={classes.socialLinks} />
                            </a>
                            <a
                                href='https://www.instagram.com/julijuneau/'
                                target='_blank'
                                rel='noreferrer'
                            >
                                <IconBrandInstagram size={20} className={classes.socialLinks} />
                            </a>
                            <a
                                href='https://www.tiktok.com/@julijuneauglass'
                                target='_blank'
                                rel='noreferrer'
                            >
                                <IconBrandTiktokFilled size={20} className={classes.socialLinks} />
                            </a>
                            <IconBrandPinterest size={20} className={classes.socialLinks} />
                            <IconBrandTwitterFilled size={20} className={classes.socialLinks} />
                        </div>
                    </Group>
                </Group>
            </Header>


            {/************************************    MOBILE HEADER     *******************************/}
            <Header height={40} px="md" className={[classes.mobileHeader, classes.hiddenDesktop]}>
                <Container className={classes.inner}>
                    <Group position="left" sx={{ height: '100%' }} spacing={0} className='p-0 sm:-ml-6'>
                        <Link to='/'>
                            <div className=' flex align-middle'>
                                <Logo />
                            </div>
                        </Link>
                    </Group>

                    <Burger opened={drawerOpened} onClick={toggleDrawer} color='gray' className='sm:-mr-6' />

                    <Drawer.Root
                        opened={drawerOpened}
                        onClose={closeDrawer}
                        position='right'
                        size='xs'
                        zIndex={1000000}
                    >
                        <Drawer.Overlay />

                        <Drawer.Content className='bg-black mt-10'>
                            <Drawer.Body className='px-0 pt-2'>
                                <ScrollArea>
                                    {navLinks.map((item, index) => (
                                        <div key={item.itemTitle}>
                                            <a 
                                                href={item.link} 
                                                className={pathname === item.link || smallPath === item.itemTitle.toLowerCase()
                                                    ? 'text-white no-underline uppercase transition duration-300 delay-100 text-md tracking-tight font-bold ml-2'
                                                    : 'text-zinc-500 no-underline uppercase transition duration-300 delay-100 text-md tracking-tight font-bold ml-2'
                                                }
                                            >
                                                {item.itemTitle}
                                            </a>

                                            <hr className='text-zinc-500' />

                                            {index < 3 ?
                                                <div>
                                                    {item.dropdownItems.map((dropdownItems, index) => (
                                                        <div key={dropdownItems.title}>
                                                            <a
                                                                href={dropdownItems.link}
                                                                className={pathname === dropdownItems.link
                                                                    ? 'text-white tracking-tight uppercase hover:text-white no-underline transition duration-300 delay-100 text-md ml-6'
                                                                    : 'tracking-tight uppercase text-zinc-500 hover:text-white no-underline transition duration-300 delay-100 text-md ml-6'
                                                                }
                                                            >
                                                                {dropdownItems.title}
                                                            </a>
                                                            <hr className='text-zinc-500' />
                                                        </div>
                                                    ))}
                                                </div>
                                                : <></>}

                                        </div>
                                    ))}

                                    <Group className='w-full space-x-.5 pt-4'>
                                        <div className='w-full justify-between flex px-8'>
                                            <a
                                                href='https://www.facebook.com/NomadCollection'
                                                target='_blank'
                                                rel='noreferrer'
                                            >
                                                <IconBrandFacebookFilled size={20} className={classes.socialLinks} />
                                            </a>
                                            <a
                                                href='https://www.instagram.com/julijuneau/'
                                                target='_blank'
                                                rel='noreferrer'
                                            >
                                                <IconBrandInstagram size={20} className={classes.socialLinks} />
                                            </a>
                                            <a
                                                href='https://www.tiktok.com/@julijuneauglass'
                                                target='_blank'
                                                rel='noreferrer'
                                            >
                                                <IconBrandTiktokFilled size={20} className={classes.socialLinks} />
                                            </a>
                                            <IconBrandPinterest size={20} className={classes.socialLinks} />
                                            <IconBrandTwitterFilled size={20} className={classes.socialLinks} />
                                        </div>
                                    </Group>
                                </ScrollArea>
                            </Drawer.Body>
                        </Drawer.Content>
                    </Drawer.Root>
                </Container>
            </Header>
        </div>
    );
}

export default Navbar



                    {/* {location.pathname.includes('/juli+dashboard')
                        ? <Menu>
                            <Menu.Target>
                                <UnstyledButton
                                    sx={(theme) => ({
                                        display: 'flex',
                                        padding: theme.spacing.md,
                                        color: theme.colorScheme === 'dark' ? theme.colors.dark[0] : theme.black
                                    })}
                                    className='p-0 sm:-ml-6 '>

                                    <Group position="left" sx={{ height: '100%' }} spacing={0}>
                                        <IconChevronDown className='flex text-zinc-500 align-middle' />
                                        <div className='flex align-middle'>
                                            <Logo />
                                        </div>
                                    </Group>
                                </UnstyledButton>
                            </Menu.Target>

                            <Menu.Dropdown>

                                <Menu.Item>
                                    <UserButton
                                        image="https://res.cloudinary.com/dp22pyubg/image/upload/c_thumb,w_200,g_face/v1688958486/Juli_Profile/juli_mzf695.jpg"
                                        name="Juli Juneau"
                                        email={auth.email}
                                        link='/juli+dashboard'
                                    />
                                </Menu.Item>

                                <Menu.Divider />

                                <Menu.Label>Links</Menu.Label>
                                {collections.map((col) => (
                                    <Menu.Item key={col.label}>
                                        <a
                                            href={`juli+dashboard/${col.link}`}
                                            // onClick={() => navigate(`juli+dashboard/${col.link}`)}
                                            className={classes.collectionLink}
                                        >
                                            <span style={{ marginRight: rem(9), fontSize: rem(16) }}>{col.emoji}</span>{' '}
                                            {col.label}
                                        </a>

                                    </Menu.Item>
                                ))}

                            </Menu.Dropdown>
                        </Menu> */}