import React from 'react';
import { Text, Box, Stack } from '@mantine/core';
import { IconPhone, IconAt } from '@tabler/icons-react';
import { useIconStyles as useStyles } from './Contact_Styles/ContactStyles';

function ContactIcon({
    icon: Icon,
    title,
    description,
    ...others
}) {
    const { classes, cx } = useStyles();
    return (
        <div className={cx(classes.wrapper)} {...others}>
            <Box mr="md">
                <Icon size="1.5rem" />
            </Box>
            <div>
                <Text size="xs" className={classes.title}>
                    {title}
                </Text>
                <Text className={classes.description}>{description}</Text>
            </div>
        </div>
    );
}

const MOCKDATA = [
    { title: 'Email', description: 'jjuneau@nomadcollection.com', icon: IconAt },
    { title: 'Phone', description: '(504) 615-2113', icon: IconPhone },
];

export function ContactIconsList({ data = MOCKDATA }) {
    const items = data.map((item, index) => <ContactIcon key={index} {...item} />);
    return <Stack>{items}</Stack>;
}