import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from '@mantine/core';
import { Outlet } from 'react-router-dom';

const Layout = () => {

    const navigate = useNavigate();

    const navigateBack = () => {
        navigate('/juli+dashboard')
    }


    return (
        <div className='flex w-full'>
            <div className='flex w-full pb-8 justify-center'>
                <div className='w-full flex flex-col 520:max-w-[500px] max-w-[400px]'>
                    <div className='flex text-white py-8 text-2xl font-light uppercase'>
                        Hey, Juli!
                    </div>

                    <div className='flex flex-col space-y-4 bg-[#1A1B1E] w-full h-full rounded-xl'>
                        <div className='pt-8 pl-8'>
                            <Button 
                                onClick={() => navigateBack()}
                            >
                                Back
                            </Button>
                        </div>

                        <Outlet />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Layout