import {
    IconBulb,
    IconUser,
    IconCheckbox,
} from '@tabler/icons-react';

export const BASE_URL = process.env.REACT_APP_BASE_URL;

export const navLinks = [
    {
        itemTitle: 'Home',
        link: '/',
        dropdownItems: []
    },
    {
        itemTitle: 'About',
        link: '/about/juli+juneau+bio',
        dropdownItems: []
    },
    {
        itemTitle: 'Glass',
        dropdownItems: [
            {
                title: 'Featured Works',
                link: '/glass/featured'
            },
            {
                title: 'Artist at Work',
                link: '/glass/artist+at+work'
            },
            {
                title: 'Galleries',
                link: '/glass/find+juli+juneau'
            },
            {
                title: 'Custom Awards',
                link: '/glass/awards'
            },
            {
                title: 'Happy Clients',
                link: '/glass/happy+clients'
            }
        ]
    },
    {
        itemTitle: 'Memorials',
        link: '/memorials',
        dropdownItems: []
    },
    {
        itemTitle: 'Press',
        link: '/press',
        dropdownItems: []
    },
    {
        itemTitle: 'Installations',
        link: '/installations',
        dropdownItems: []
    },
    // {
    //     itemTitle: 'Shop',
    //     link: '/shop/main',
    //     dropdownItems: []
    // },
    {
        itemTitle: 'Contact',
        link: '/contact',
        dropdownItems: []
    },
];

export const dashNavLinks = [
    { icon: IconBulb, label: 'Orders' },
    { icon: IconCheckbox, label: 'Tasks' },
    { icon: IconUser, label: 'Contacts' },
];

export const dashMenu = [
    { label: 'Add an Item', link: '/juli+dashboard/add' },
    { label: 'Edit an Item', link: '/juli+dashboard/edit' },
    { label: 'Delete an Item', link: '/juli+dashboard/delete' },
    // { label: 'View Shop Items', link: '/juli+dashboard/view' },

];

export const uploadProductTypes = [
    { selector: 'Oblongs', input: 'oblongs' },
    { selector: 'Bowls', input: 'bowls' },
    { selector: 'Wall Pieces', input: 'wall-pieces' },
    { selector: 'Vases', input: 'vases' },
    { selector: 'Glasses', input: 'glasses' },
    { selector: 'Globes', input: 'globes' },
    { selector: 'Ring Holders', input: 'ring-holders' },
    { selector: 'Paper Weights', input: 'paper-weights' },
    { selector: 'Hearts', input: 'hearts' },
    { selector: 'Orbs/Balls', input: 'orbs-balls' }
];

export const USDollar = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
});

export const pressImages = [
    {
        image_url: 'https://res.cloudinary.com/dp22pyubg/image/upload/v1689753907/online_mid_city_dktq32.png',
        href: 'https://midcitymessenger.com/2023/01/11/mart-for-arts-sake-juli-juneau-exhibits-her-art-glass-in-city-park/',
        height: '350',
        alt: ''
    },
    {
        image_url: 'https://res.cloudinary.com/dp22pyubg/image/upload/v1689753899/april_article_nt1ysv.png',
        href: 'https://www.insideneworleansmagazine.com/local-business/glass-with-class/',
        height: '350',
        alt: ''
    },
    {
        image_url: 'https://res.cloudinary.com/dp22pyubg/image/upload/v1689753890/happy_place_bio_yjqhl0.png',
        href: 'https://myhappyplacegallery.com/artist-biography.php?artistId=368382&artist=Juli%20Juneau',
        height: '350',
        alt: ''
    },
    // {
    //     image_url: 'https://res.cloudinary.com/dp22pyubg/video/upload/v1720051809/Press/IMG_7435_csnoug',
    //     href: null,
    //     height: '350',
    //     alt: ''
    // },
    {
        image_url: 'https://res.cloudinary.com/dp22pyubg/image/upload/v1700898774/Press/mag_t3rovc.jpg',
        href: null,
        height: '600',
        alt: ''
    },
    {
        image_url: 'https://res.cloudinary.com/dp22pyubg/image/upload/v1700898775/Press/mag2_tuevrk.jpg',
        href: null,
        height: '600',
        alt: ''
    },
    {
        image_url: 'https://res.cloudinary.com/dp22pyubg/image/upload/v1700898778/Press/newspaper_e9mdl0.jpg',
        href: null,
        height: '550',
        alt: ''
    },
    {
        image_url: 'https://res.cloudinary.com/dp22pyubg/image/upload/v1715879464/Press/IMG_6688_jrgrjo.jpg',
        href: null,
        height: '550',
        alt: ''
    },
    {
        image_url: 'https://res.cloudinary.com/dp22pyubg/image/upload/v1715877360/Press/IMG_6689_br0z3m.jpg',
        href: null,
        height: '550',
        alt: ''
    },
]

// outdoors = 0, home = 1, corp = 2
export const installationImages = [
    {
        image_url: 'https://res.cloudinary.com/dp22pyubg/image/upload/v1700898747/Installations/permanent-outdoor_mloirk.jpg',
        text: 'Mobile Botanical Gardens - Mobile, Alabama',
        alt: '',
        space: 0
    },
    {
        image_url: 'https://res.cloudinary.com/dp22pyubg/image/upload/v1700898748/Installations/pool-installation_dttdzi.jpg',
        text: 'Garden District - New Orleans, Louisiana',
        alt: '',
        space: 0
    },
    {
        image_url: 'https://res.cloudinary.com/dp22pyubg/image/upload/v1700898749/Installations/senior-installation_uhdlfk.jpg',
        text: 'Dallas, Texas',
        alt: '',
        space: 2
    },
    {
        image_url: 'https://res.cloudinary.com/dp22pyubg/image/upload/v1700898750/Installations/senior-living-ins-2_i1dqbj.jpg',
        text: 'Phoenix, Arizona',
        alt: '',
        space: 2
    },
    {
        image_url: 'https://res.cloudinary.com/dp22pyubg/image/upload/v1700898751/Installations/wall_y97vtb.jpg',
        text: 'Warehouse District - New Orleans, Louisiana',
        alt: '',
        space: 1
    },
    {
        image_url: 'https://res.cloudinary.com/dp22pyubg/image/upload/v1700898753/Installations/wall-close-up_nkywnj.jpg',
        text: 'Warehouse District - New Orleans, Louisiana',
        alt: '',
        space: 1
    },
    {
        image_url: 'https://res.cloudinary.com/dp22pyubg/image/upload/v1700898754/Installations/wall-close-up-2_qknto4.jpg',
        text: 'Warehouse District - New Orleans, Louisiana',
        alt: '',
        space: 1
    },
    {
        image_url: 'https://res.cloudinary.com/dp22pyubg/image/upload/v1720123508/Installations/IMG_7596_a0tiet.jpg',
        text: 'TBD',
        alt: '',
        space: 1
    },
    {
        image_url: 'https://res.cloudinary.com/dp22pyubg/image/upload/v1720123506/Installations/IMG_8091_colrrl.jpg',
        text: 'TBD',
        alt: '',
        space: 1
    },
    {
        image_url: 'https://res.cloudinary.com/dp22pyubg/image/upload/v1720123504/Installations/IMG_8092_c2pw9o.jpg',
        text: 'TBD',
        alt: '',
        space: 1
    },
    {
        image_url: 'https://res.cloudinary.com/dp22pyubg/image/upload/v1700898755/Installations/avanti_atknr7.jpg',
        text: 'Lafayette, Louisiana',
        alt: '',
        space: 2
    },
    {
        image_url: 'https://res.cloudinary.com/dp22pyubg/image/upload/v1700898756/Installations/avanti-install_wpczc8.jpg',
        text: 'Covington, Louisiana',
        alt: '',
        space: 2
    },
    {
        image_url: 'https://res.cloudinary.com/dp22pyubg/image/upload/v1700898757/Installations/avanti-juli_ofkaf8.jpg',
        text: 'Houston, Texas',
        alt: '',
        space: 2
    },
    {
        image_url: 'https://res.cloudinary.com/dp22pyubg/image/upload/v1700898758/Installations/beach-install_dxjals.jpg',
        text: 'Seabrook Island, South Carolina',
        alt: '',
        space: 1
    },
    {
        image_url: 'https://res.cloudinary.com/dp22pyubg/image/upload/v1700898760/Installations/beach-install-far-away_udowri.jpg',
        text: 'Seabrook Island, South Carolina',
        alt: '',
        space: 1
    },
    {
        image_url: 'https://res.cloudinary.com/dp22pyubg/image/upload/v1700898761/Installations/ceiling_g8dzvp.jpg',
        text: 'Kiawah Island, South Carolina',
        alt: '',
        space: 1
    },
    {
        image_url: 'https://res.cloudinary.com/dp22pyubg/image/upload/v1700898762/Installations/custom-wall-installation_we1jhh.jpg',
        text: 'Phoenix, Arizona',
        alt: '',
        space: 2
    },
    {
        //          https://res.cloudinary.com/dp22pyubg/video/upload/v1720050820/IMG_2907_efqe73
        image_url: 'https://res.cloudinary.com/dp22pyubg/video/upload/v1715809760/Installations/IMG_1810_mjbndt',
        text: 'Mt. Pleasant, South Carolina',
        alt: '',
        space: 1
    },
    {
        image_url: 'https://res.cloudinary.com/dp22pyubg/image/upload/v1700898764/Installations/outdoor-installtion_teyier.jpg',
        text: 'Baton Rouge, Louisiana',
        alt: '',
        space: 0
    },
    {
        image_url: 'https://res.cloudinary.com/dp22pyubg/image/upload/v1720052217/Installations/IMG_4554_tvt5nv.jpg',
        text: 'New Orleans, Louisiana',
        alt: '',
        space: 0
    },
    {
        image_url: 'https://res.cloudinary.com/dp22pyubg/image/upload/v1720052221/Installations/IMG_4555_dappqj.jpg',
        text: 'New Orleans, Louisiana',
        alt: '',
        space: 0
    },
    {
        image_url: 'https://res.cloudinary.com/dp22pyubg/image/upload/v1720052224/Installations/IMG_4551_svbetg.jpg',
        text: 'New Orleans, Louisiana',
        alt: '',
        space: 0
    },
    {
        image_url: 'https://res.cloudinary.com/dp22pyubg/image/upload/v1700898651/Featured/Emails/7bca4085-28b5-443f-a973-df26b25cc157_shxidh.jpg',
        text: 'Mt. Pleasant, South Carolina',
        alt: '',
        space: 1
    },
    {
        image_url: 'https://res.cloudinary.com/dp22pyubg/image/upload/v1720051364/Installations/IMG_0459_lzmvhx.jpg',
        text: 'Old Metairie - New Orleans, Louisiana',
        alt: '',
        space: 1
    },
    {
        image_url: 'https://res.cloudinary.com/dp22pyubg/image/upload/v1720051362/Installations/IMG_0466_qdrxdj.jpg',
        text: 'Old Metairie - New Orleans, Louisiana',
        alt: '',
        space: 1
    },
    {
        image_url: 'https://res.cloudinary.com/dp22pyubg/image/upload/v1720051359/Installations/IMG_0469_nlsubh.jpg',
        text: 'Old Metairie - New Orleans, Louisiana',
        alt: '',
        space: 1
    },
    {
        image_url: 'https://res.cloudinary.com/dp22pyubg/image/upload/v1720051723/Installations/unnamed_iyv8fx.jpg',
        text: 'Covington, Louisiana',
        alt: '',
        space: 2
    },
];

export const awardImages = [
    { image_url: 'https://res.cloudinary.com/dp22pyubg/image/upload/v1700898705/awards/humanities_aclepo.jpg', text: '', alt: '' },
    { image_url: 'https://res.cloudinary.com/dp22pyubg/image/upload/v1700898704/awards/power-of-women_ewbewl.jpg', text: '', alt: '' },
    { image_url: 'https://res.cloudinary.com/dp22pyubg/image/upload/v1720052370/awards/IMG_3178_nnuht4.jpg', text: '', alt: '' },
    { image_url: 'https://res.cloudinary.com/dp22pyubg/image/upload/v1700898703/awards/humanities-book-of-the-year_ddxetd.jpg', text: '', alt: '' },
    { image_url: 'https://res.cloudinary.com/dp22pyubg/image/upload/v1715807060/awards/IMG_5613_jijofj.jpg', text: '', alt: '' },
    { image_url: 'https://res.cloudinary.com/dp22pyubg/image/upload/v1715807060/awards/IMG_5619_jngw4e.jpg', text: '', alt: '' },
    { image_url: 'https://res.cloudinary.com/dp22pyubg/image/upload/v1715807060/awards/IMG_8266_czc4gc.jpg', text: '', alt: '' },
    { image_url: 'https://res.cloudinary.com/dp22pyubg/image/upload/v1720123855/awards/IMG_6656_zoifq1.jpg', text: '', alt: '' },
    { image_url: 'https://res.cloudinary.com/dp22pyubg/image/upload/v1715807061/awards/IMG_8272_ojymjg.jpg', text: '', alt: '' },
    { image_url: 'https://res.cloudinary.com/dp22pyubg/image/upload/v1715807061/awards/IMG_8343_da4i5h.jpg', text: '', alt: '' },
    { image_url: 'https://res.cloudinary.com/dp22pyubg/image/upload/v1715807062/awards/IMG_8951_rdbjml.jpg', text: '', alt: '' },
    { image_url: 'https://res.cloudinary.com/dp22pyubg/image/upload/v1715807062/awards/FullSizeRender_1_um2ovc.jpg', text: '', alt: '' },
    { image_url: 'https://res.cloudinary.com/dp22pyubg/image/upload/v1715807063/awards/FullSizeRender_k0ml2i.jpg', text: '', alt: '' },
    { image_url: 'https://res.cloudinary.com/dp22pyubg/image/upload/v1715807063/awards/IMG_0691_bynooe.jpg', text: '', alt: '' },
    { image_url: 'https://res.cloudinary.com/dp22pyubg/image/upload/v1715807064/awards/IMG_3178_nrdoik.jpg', text: '', alt: '' },
    { image_url: 'https://res.cloudinary.com/dp22pyubg/image/upload/v1715807064/awards/IMG_4099_j2ws8o.jpg', text: '', alt: '' },
];

export const workImages = [
    { image_url: 'https://res.cloudinary.com/dp22pyubg/image/upload/v1700898527/At_Work/BNG_Juli_Demo013_omaeb6.jpg', text: '', alt: '' },
    { image_url: 'https://res.cloudinary.com/dp22pyubg/image/upload/v1700898526/At_Work/BNG_Juli_Demo010_kf8mjl.jpg', text: '', alt: '' },
    { image_url: 'https://res.cloudinary.com/dp22pyubg/image/upload/v1700898526/At_Work/BNG_Juli_Demo09_iqtbkc.jpg', text: '', alt: '' },
    { image_url: 'https://res.cloudinary.com/dp22pyubg/image/upload/v1700898526/At_Work/BNG_Juli_Demo020_twrxrb.jpg', text: '', alt: '' },
    { image_url: 'https://res.cloudinary.com/dp22pyubg/image/upload/v1700898526/At_Work/BNG_Juli_Demo019_shta5g.jpg', text: '', alt: '' },
    { image_url: 'https://res.cloudinary.com/dp22pyubg/image/upload/v1700898525/At_Work/BNG_Juli_Demo018_lwk5iw.jpg', text: '', alt: '' },
    { image_url: 'https://res.cloudinary.com/dp22pyubg/image/upload/v1700898525/At_Work/BNG_Juli_Demo017_jwmdb0.jpg', text: '', alt: '' },
    { image_url: 'https://res.cloudinary.com/dp22pyubg/image/upload/v1700898525/At_Work/BNG_Juli_Demo015_e2nrqj.jpg', text: '', alt: '' },
    { image_url: 'https://res.cloudinary.com/dp22pyubg/image/upload/v1715789129/At_Work/IMG_5417_ty8lfz.jpg', text: '', alt: '' },
    { image_url: 'https://res.cloudinary.com/dp22pyubg/image/upload/v1715789123/At_Work/IMG_2805_mzkyp6.jpg', text: '', alt: '' },
    { image_url: 'https://res.cloudinary.com/dp22pyubg/video/upload/v1721372381/At_Work/v09044560000bnt5j22vu196tssau7r0_lfq2oi', text: '', alt: '' },
    { image_url: 'https://res.cloudinary.com/dp22pyubg/image/upload/v1715789117/At_Work/IMG_2794_mqcxwe.jpg', text: '', alt: '' },
    { image_url: 'https://res.cloudinary.com/dp22pyubg/image/upload/v1715789095/At_Work/aw1_weukvm.png', text: '', alt: '' },
    { image_url: 'https://res.cloudinary.com/dp22pyubg/image/upload/v1715789107/At_Work/IMG_2241_gk3yky.jpg', text: '', alt: '' },
    { image_url: 'https://res.cloudinary.com/dp22pyubg/image/upload/v1715789101/At_Work/aw2_dwhqpz.png', text: '', alt: '' },
    { image_url: 'https://res.cloudinary.com/dp22pyubg/video/upload/v1715789573/At_Work/12second_z2g1cx', text: '', alt: '' },
];

export const memorialImages = [
    { image_url: 'https://res.cloudinary.com/dp22pyubg/image/upload/v1721371211/Memorials/IMG_0115_d8htsp.jpg', text: '', alt: '' },
    { image_url: 'https://res.cloudinary.com/dp22pyubg/image/upload/v1721371212/Memorials/IMG_0113_wrvnxe.jpg', text: '', alt: '' },
    { image_url: 'https://res.cloudinary.com/dp22pyubg/image/upload/v1721371214/Memorials/IMG_5390_mcifc7.jpg', text: '', alt: '' },
    { image_url: 'https://res.cloudinary.com/dp22pyubg/image/upload/v1721371215/Memorials/IMG_5396_omi7ig.jpg', text: '', alt: '' },
];

export const galleryImages = [
    // { image_url: 'https://res.cloudinary.com/dp22pyubg/image/upload/v1715807180/Shows/IMG_7716_wz2o4k.jpg', text: '', alt: '' },
    { image_url: 'https://res.cloudinary.com/dp22pyubg/image/upload/v1700898803/Shows/ariodante3_zqiddf.jpg', text: '', alt: '' },
    { image_url: 'https://res.cloudinary.com/dp22pyubg/image/upload/v1700898801/Shows/ariodante2_kk6elo.jpg', text: '', alt: '' },
    { image_url: 'https://res.cloudinary.com/dp22pyubg/image/upload/v1700898800/Shows/ariodante_j3gxyz.jpg', text: '', alt: '' },
    // { image_url: 'https://res.cloudinary.com/dp22pyubg/image/upload/v1700898799/Shows/unnamed_bhmjtg.jpg', text: '', alt: '' },
    { image_url: 'https://res.cloudinary.com/dp22pyubg/image/upload/v1715807179/Shows/IMG_2988_lc6s3t.jpg', text: '', alt: '' },
    { image_url: 'https://res.cloudinary.com/dp22pyubg/image/upload/v1715807179/Shows/IMG_2989_jmjz9w.jpg', text: '', alt: '' },
    { image_url: 'https://res.cloudinary.com/dp22pyubg/image/upload/v1715807180/Shows/IMG_1973_juqhgw.jpg', text: '', alt: '' },
    { image_url: 'https://res.cloudinary.com/dp22pyubg/image/upload/v1715807181/Shows/IMG_2018_zw8fbx.jpg', text: '', alt: '' },
    { image_url: 'https://res.cloudinary.com/dp22pyubg/image/upload/v1715807182/Shows/IMG_2981_h1rho8.jpg', text: '', alt: '' },
    { image_url: 'https://res.cloudinary.com/dp22pyubg/image/upload/v1715807182/Shows/IMG_2984_sa4k8v.jpg', text: '', alt: '' },
    { image_url: 'https://res.cloudinary.com/dp22pyubg/image/upload/v1715807183/Shows/IMG_2985_ufcdcc.jpg', text: '', alt: '' },
];

export const featuredArtImages = [
    { image_url: 'https://res.cloudinary.com/dp22pyubg/image/upload/v1700898651/Featured/Emails/7bca4085-28b5-443f-a973-df26b25cc157_shxidh.jpg', text: '', alt: '' },
    { image_url: 'https://res.cloudinary.com/dp22pyubg/image/upload/v1700898650/Featured/Emails/professional_ywiucz.jpg', text: '', alt: '' },
    { image_url: 'https://res.cloudinary.com/dp22pyubg/image/upload/v1689924689/Featured/display_ljcu9h.png', text: '', alt: '' },
    { image_url: 'https://res.cloudinary.com/dp22pyubg/image/upload/v1689924720/Featured/blue_vase_iydlpr.png', text: '', alt: '' },
    { image_url: 'https://res.cloudinary.com/dp22pyubg/image/upload/v1689924722/Featured/mardi_gras_balls_czfzp4.png', text: '', alt: '' },
    { image_url: 'https://res.cloudinary.com/dp22pyubg/image/upload/v1689924731/Featured/bowl2_paetnp.png', text: '', alt: '' },
    { image_url: 'https://res.cloudinary.com/dp22pyubg/image/upload/v1689924733/Featured/mardi_gras_cups_ouvrk5.png', text: '', alt: '' },
    { image_url: 'https://res.cloudinary.com/dp22pyubg/image/upload/v1689924743/Featured/bowl_yqftzb.png', text: '', alt: '' },
    { image_url: 'https://res.cloudinary.com/dp22pyubg/video/upload/v1715879601/Featured/IMG_7435_i75shg', text: '', alt: '' },
    { image_url: 'https://res.cloudinary.com/dp22pyubg/image/upload/v1721372409/Featured/IMG_5538_fdlwpw.jpg', text: '', alt: '' },
    { image_url: 'https://res.cloudinary.com/dp22pyubg/image/upload/v1720052056/Featured/IMG_8482_ks72zo.jpg', text: '', alt: '' },
    { image_url: 'https://res.cloudinary.com/dp22pyubg/image/upload/v1700898655/Featured/Emails/juli-wall-installation_xvxra6.jpg', text: '', alt: '' },
    { image_url: 'https://res.cloudinary.com/dp22pyubg/image/upload/v1700898654/Featured/Emails/cutom-home-install_ljtudh.jpg', text: '', alt: '' },
    { image_url: 'https://res.cloudinary.com/dp22pyubg/image/upload/v1700898653/Featured/Emails/cutom-blown_pecolt.jpg', text: '', alt: '' },
    { image_url: 'https://res.cloudinary.com/dp22pyubg/image/upload/v1700898652/Featured/Emails/avanti-featured_impbwa.jpg', text: '', alt: '' },
    { image_url: 'https://res.cloudinary.com/dp22pyubg/image/upload/v1715879591/Featured/IMG_6310_homtub.jpg', text: '', alt: '' },
    { image_url: 'https://res.cloudinary.com/dp22pyubg/image/upload/v1715879592/Featured/IMG_6312_jkiuek.jpg', text: '', alt: '' },

];

export const clientImages = [
    { image_url: 'https://res.cloudinary.com/dp22pyubg/image/upload/v1700898732/Happy_Clients/shreveport_zrq4jk.jpg', text: '', alt: '' },
    { image_url: 'https://res.cloudinary.com/dp22pyubg/image/upload/v1700898734/Happy_Clients/happy-cat_nimzyo.jpg', text: '', alt: '' },
    { image_url: 'https://res.cloudinary.com/dp22pyubg/image/upload/v1715807589/Happy_Clients/FullSizeRender_x2c0e7.jpg', text: '', alt: '' },
    { image_url: 'https://res.cloudinary.com/dp22pyubg/image/upload/v1715807589/Happy_Clients/image000000_vjbrkj.jpg', text: '', alt: '' },
    { image_url: 'https://res.cloudinary.com/dp22pyubg/image/upload/v1715807590/Happy_Clients/image000001_m3te6i.jpg', text: '', alt: '' },
    { image_url: 'https://res.cloudinary.com/dp22pyubg/image/upload/v1715879589/Featured/IMG_5038_b7hqfz.jpg', text: '', alt: '' },
    { image_url: 'https://res.cloudinary.com/dp22pyubg/image/upload/v1715807590/Happy_Clients/IMG_0251_jdkx3j.jpg', text: '', alt: '' },
    { image_url: 'https://res.cloudinary.com/dp22pyubg/image/upload/v1715807591/Happy_Clients/IMG_0326_ikv5qh.jpg', text: '', alt: '' },
    { image_url: 'https://res.cloudinary.com/dp22pyubg/image/upload/v1715807592/Happy_Clients/IMG_0973_mz1ktw.jpg', text: '', alt: '' },
    { image_url: 'https://res.cloudinary.com/dp22pyubg/video/upload/v1720050820/Happy_Clients/IMG_2907_efqe73', text: '', alt: '' },
    { image_url: 'https://res.cloudinary.com/dp22pyubg/image/upload/v1715807593/Happy_Clients/IMG_1823_aylpib.jpg', text: '', alt: '' },
    { image_url: 'https://res.cloudinary.com/dp22pyubg/image/upload/v1720123682/Happy_Clients/IMG_7739_qpkqbc.jpg', text: '', alt: '' },
    { image_url: 'https://res.cloudinary.com/dp22pyubg/image/upload/v1720123687/Happy_Clients/IMG_0769_fcbfyl.jpg', text: '', alt: '' },
    { image_url: 'https://res.cloudinary.com/dp22pyubg/image/upload/v1720052311/Happy_Clients/IMG_4206_yz4mtx.jpg', text: '', alt: '' },
    { image_url: 'https://res.cloudinary.com/dp22pyubg/image/upload/v1715807594/Happy_Clients/IMG_2060_rvwsh8.jpg', text: '', alt: '' },
    { image_url: 'https://res.cloudinary.com/dp22pyubg/image/upload/v1715807594/Happy_Clients/IMG_2670_ftpjjw.jpg', text: '', alt: '' },
    { image_url: 'https://res.cloudinary.com/dp22pyubg/image/upload/v1720052180/Happy_Clients/IMG_3692_ge8jov.jpg', text: '', alt: '' },
    { image_url: 'https://res.cloudinary.com/dp22pyubg/image/upload/v1715807595/Happy_Clients/IMG_2676_wat9th.jpg', text: '', alt: '' },
    { image_url: 'https://res.cloudinary.com/dp22pyubg/image/upload/v1715807596/Happy_Clients/IMG_2723_ysnp9m.jpg', text: '', alt: '' },
    { image_url: 'https://res.cloudinary.com/dp22pyubg/image/upload/v1715807597/Happy_Clients/IMG_3882_arwu3h.jpg', text: '', alt: '' },
    { image_url: 'https://res.cloudinary.com/dp22pyubg/image/upload/v1715807597/Happy_Clients/IMG_3969_fkyooy.jpg', text: '', alt: '' },
    { image_url: 'https://res.cloudinary.com/dp22pyubg/image/upload/v1715807598/Happy_Clients/IMG_3970_isber6.jpg', text: '', alt: '' },
    { image_url: 'https://res.cloudinary.com/dp22pyubg/image/upload/v1715807599/Happy_Clients/IMG_7313_fvwz3c.jpg', text: '', alt: '' },
];

export const galleryList = [
    'Ariodante Gallery, Julia St.., New Orleans, LA',
    'Pollock Glass Gallery, Magazine St., New Orleans, LA ',
    'LSU Museum of Art Gallery, Baton Rouge, LA',
    'Adorn, Royal Street., New Orleans, LA',
    'LA Guild, Canal St., New Orleans, LA',
    'University of Lafayette Museum of Art Gallery, Lafayette, LA',
    'Mobile Botanical Gardens, Mobile, AL',
    'My Happy Place Gallery, Ocean Springs, MS',
    'Hearth and Soul, Austin, TX and Tallahassee, FL',
    'The Everyday Gardener, Jackson, MS',
    'Craftsman’s Guild of Art Gallery, Jackson, MS',
    'Front Street Market, Laurel, MS',
    'Perdido Artist Gallery, Perdido Key, FL ',
]

export const artistBio =
    [
        [
            `Juli Juneau made New Orleans her home in 1989. In 1995, she created Nomad Collection, 
    which began as an import business featuring Balinese sarongs, Tuareg jewelry sourced from her travels in Mali, 
    and her own travel photography.   She has traveled extensively throughout the world including a 2 1/2 year solo adventure from Dakar, 
    Senegal to Cape Town, South Africa. This inspiring journey through 23 African countries by land (boat, barge, train, even donkey cart – 
    any means possible!) made the African continent and her people, music, culture, wildlife, and geography especially dear to Juli’s heart. 
    In 2022, Juli hiked the Camino de Santiago, 500 miles,crossing the French Pyrenees ending in Santiago, Spain. The next Himalayan spiritual journey was 2023, 
    including a three day hike around Mt Kailash(the holiest mountain in the world), Mt Everest Base Camp ,Tibet, Nepal, and northern India.`
        ],
        [
            `In 2003, Juli spent one year volunteering with the International AIDS Trust in southern Africa. Once home she“brought a piece of Africa“ to Louisiana and there, 
    Juli bottle raised baby zebra and many kangaroos, surrounded by giraffes and camels and a variety of other “wild animals“ she tamed. `
        ],
        [
            `"The organic creation of glass is part of its mystique and power: a lightning storm, a bolt ignites and a piece of glass appears-much like a genie rising from the desert sands of Africa. 
    This magic is the lure and the passion of glass for me. Blown glass is extremely seductive and attracts the feminine side of us all, yet creating these lush and sensual forms is powerful 
    and intense. 
    When I handle a large mass of molten liquid glass at the end of my 5 foot blow pipe and face the blasting heat of 2300° furnaces, 
    I find it a macho, masculine, sometimes Herculean task requiring every drop of physical strength and stamina I can muster. 
    Yet, at times, my touch must be as delicate as brushing a hair from my face." `
        ],
        [
            `After years of admiring blown glass, Juli began to create her own glass art in 1999. Her work is displayed in a multitude of US and international collections including those of 
    Sir Anthony Hopkins, Brad Pitt, Oprah Winfrey headquarters permanent collection, Lou Gosset Jr., Angelina Jolie, Hoda Kolb, Dave Chappelle, Terence Blanchard, Dustin Hoffman, 
    Cokie Roberts, Craig Tracy Fine Art Body Painting/Skinwars TV, Gail Benson of the New Orleans Saints, Goldring family, the first ladies of LA and MS, Anheuser Busch Private Collection, 
    Ritz-Carlton New Orleans and Citizens Bank permanent collections.  Juli has been commissioned by the Louisiana Endowment for the Humanities to design and create their annual 
    “Bright Lights”awards since 2015. She also creates the annual awards for the American Red Cross “Power of Women”, Eden House, and many more. For large scale permanent 
    installations for Avanti Senior Living communities may currently be seen at seven locations, Towne Lake, Augusta Pines, and Vision Park in Houston,TX, 
    Flower Mound in Dallas, TX, Avanti in Covington, LA; Lafayette, LA; and Phoenix, AZ.`
        ]
    ]
