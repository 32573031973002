import React, { useState, useEffect } from 'react';
import { generatePath, useParams, useNavigate } from 'react-router-dom';
import { TextInput, Table, Button, CloseButton, Modal } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import api from '../../../api/axios';

const EditItem = () => {

    const { id } = useParams();

    const [item, setItem] = useState();
    const [loading, setLoading] = useState(true);
    const [edit, setEdit] = useState();
    const [initialValue, setInitialValue] = useState();

    const [openedSuccess, { openSuccess, closeSuccess }] = useDisclosure(false);
    const [openedFailure, { openFailure, closeFailure }] = useDisclosure(false);

    useEffect(() => {
        const fetchItemDetails = async () => {
            try {
                const path = generatePath('/fetchitemdetails/:id', {
                    id: id
                })

                const response = await api.get(path)
                setLoading(false);
                setItem(response.data);

            } catch (err) {
                console.log(err.message);
            }
        }

        fetchItemDetails()
    }, []);

    let details = (!loading) ? {
        'Title': item[0].title,
        'Price': item[0].price,
        'Description': item[0].description,
        'Size': item[0].size,
        'Product Type': item[0].product_type
    } : [];

    const handleEdit = (modifier, detail) => {
        setEdit(modifier);
        setInitialValue(detail);
    };

    const handleClose = () => {
        setEdit();
    };

    const handleEditSubmit = async (id, modifier, detail) => {
        try {
            const response = await api.post('/change/item/info', {
                id: id,
                modifier: modifier,
                detail: detail
            });
            if (response.data === 'fail') {
                //failure redirect
                openFailure()

            } else {
                //success redirect
                openSuccess()

            }
        } catch (err) {
            console.log(err.message);
        }
    };

    return (
        <div className='flex flex-col px-8'>
            <Modal opened={openedSuccess} onClose={closeSuccess} title="Success">
                Edit Successful
            </Modal>

            <Modal opened={openedFailure} onClose={closeFailure} title="Failure">
                Edit Failure Please Try Again
            </Modal>
            
            <div className='flex justify-center text-lg uppercase text-white pb-4 '>
                Edit Item
            </div>



            <Table verticalSpacing="md">

                <thead>
                    <tr>
                        <th>Modifier</th>
                        <th>Product Detail</th>
                        <th></th>
                    </tr>
                </thead>

                <tbody className=''>
                    {Object.keys(details).map((row, index) => (
                        <tr>

                            <td>
                                {Object.keys(details)[index]}
                            </td>
                            <td>
                                {edit === Object.keys(details)[index]
                                    ? <TextInput
                                        defaultValue={initialValue}
                                        onChange={(event) => setInitialValue(event.currentTarget.value)}
                                    />
                                    : details[Object.keys(details)[index]]}
                            </td>
                            <td>
                                {edit !== Object.keys(details)[index]
                                    ? <Button
                                        onClick={() => handleEdit(Object.keys(details)[index], details[Object.keys(details)[index]])}
                                    >
                                        Edit
                                    </Button>
                                    : <div className='flex flex-row align-middle'>
                                        <Button
                                            onClick={() => handleEditSubmit(id, Object.keys(details)[index], initialValue)}
                                        >
                                            Submit
                                        </Button>
                                        <CloseButton
                                            size="lg"
                                            iconSize={20}
                                            onClick={() => handleClose()}
                                        />
                                    </div>
                                }
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>

        </div>
    )
}

export default EditItem