import React from 'react';
import UploadInputs from '../ItemUpload/UploadInputs';

const ShopAdd = () => {
    return (
        <div>
            <UploadInputs />
        </div>
    )
}

export default ShopAdd