import React from 'react';
import { Outlet } from 'react-router-dom';

const About = () => {
    return (

        <Outlet />

    )
}

export default About