import React from 'react';
import { useNavigate } from 'react-router-dom' 
import { Text, Title, SimpleGrid, TextInput, Textarea, Button, Group, Paper, Modal } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { ContactIconsList } from './ContactIcons';
import { useContactStyles as useStyles } from './Contact_Styles/ContactStyles';
import { useForm } from '@mantine/form';
import api from '../../api/axios';

const EMAIL_REGEX = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

const Contact = () => {
    const { classes, theme } = useStyles();
    const navigate = useNavigate();
    const [opened, { open, close }] = useDisclosure(false);

    const form = useForm({
        initialValues: { email: '', message: '', name: '' },
        validate: (values) => ({
            email:
                values.email.length === 0
                    ? 'Your email is required'
                    : EMAIL_REGEX.test(values.email.trim())
                        ? null
                        : 'Invalid email format',
            name:
                values.name.length === 0
                    ? 'Your name is required'
                    : null,
            message:
                values.message.length === 0
                    ? 'A message is required'
                    : null,
        }),
    });

    const handleSubmit = async (values) => {
        try {
            const response = await api.post('/contact-form', values)

            response ? open() : console.log('server error')

        } catch (err) {
            console.log(err.message);
        }
    };

    const handleModalClose = () => {
        close();
        navigate('/');
    }

    return (
        <div className='flex w-full justify-center items-center sm:items-start px-4 pt-12'>

            <Modal.Root
                opened={opened}
                onClose={handleModalClose}
                centered
                overlayProps={{
                    color: theme.colorScheme === 'dark' ? theme.colors.dark[9] : theme.colors.gray[2],
                    opacity: 0.55,
                    blur: 3,
                  }}
            >
                <Modal.Overlay />
                <Modal.Content>
                    <Modal.Header>
                        <Modal.Title>Woohoo! Your Message was Sent!</Modal.Title>
                        <Modal.CloseButton />
                    </Modal.Header>
                    <Modal.Body>I'll be in Touch Shortly</Modal.Body>
                </Modal.Content>
            </Modal.Root>

            <Paper className={[classes.wrapper, 'bg-modals']} >
                <SimpleGrid cols={2} spacing={50} breakpoints={[{ maxWidth: 'sm', cols: 1 }]}>
                    <div>
                        <Title weight={300} className={classes.title}>Have a question?</Title>
                        <Text weight={300} className={classes.description} mt="sm" mb={30}>
                            Let me bring your ideas to life! Contact me here or through phone, text or email.
                        </Text>

                        <ContactIconsList variant="white" />

                    </div>
                    <div className={classes.form}>
                        <form onSubmit={form.onSubmit((values) => handleSubmit(values))}>
                            <TextInput
                                label="Email"
                                placeholder="your@email.com"
                                withAsterisk
                                classNames={{ input: classes.input, label: classes.inputLabel }}
                                {...form.getInputProps('email')}
                            />
                            <TextInput
                                label="Name"
                                placeholder="John Doe"
                                withAsterisk
                                mt="md"
                                classNames={{ input: classes.input, label: classes.inputLabel }}
                                {...form.getInputProps('name')}
                            />
                            <Textarea
                                withAsterisk
                                label="Your message"
                                placeholder="I want to order your goods"
                                minRows={4}
                                mt="md"
                                classNames={{ input: classes.input, label: classes.inputLabel }}
                                {...form.getInputProps('message')}
                            />

                            <Group className='flex w-full ' mt="xl">
                                <Button
                                    className='flex w-full '
                                    classNames={{ inner: 'flex w-full justify-center'}}
                                    // className={classes.control}
                                    color='yellow'
                                    variant='default'
                                    type='submit'
                                >
                                    Send message
                                </Button>
                            </Group>
                        </form>
                    </div>
                </SimpleGrid>
            </Paper>
        </div>
    );
}

export default Contact